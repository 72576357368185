<template>
  <div class="public-link-show">
    <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style="display: none;">
      <defs>
        <g id="invoxia-logo">
          <path
          fill="#FFFFFF"
          d="M294.1,34.4c-27.2,0.1-45.2,19.1-45,47.4c0.3,23.2,20.8,42.8,45.2,42.5c29-0.3,45-22.5,45.3-42.9
          C340.1,53.3,321.2,34.3,294.1,34.4z M294.3,107.8c-16.6,0-28.4-12.1-28.4-29c0-16.2,11.9-27.9,28.3-28
          c16.3-0.1,28.4,11.8,28.4,27.9C322.6,95.7,310.9,107.8,294.3,107.8z"
          />
          <path
          fill="#FFFFFF"
          d="M97.8,34.4c-27.3,0.1-45.2,19.1-45,47.4V122h16.8V78.8c0-16.2,11.9-28,28.3-28.1
          c16.3-0.1,28.4,11.8,28.4,27.9V122l17-0.1l0-40.5C143.8,53.3,124.9,34.3,97.8,34.4z"
          />
          <ellipse fill="#FFFFFF" cx="23.8" cy="10.9" rx="11.6" ry="10.9"/>
          <ellipse fill="#FFFFFF" cx="459.7" cy="10.9" rx="11.6" ry="10.9"/>
          <path
          fill="#FFFFFF"
          d="M220.9,38.8l-25.1,61.9l-25.1-61.9c-0.6-1.6-2.1-2.6-3.8-2.6h-14.7l34.6,83.7c0.6,1.5,2.1,2.5,3.8,2.5H201
          c1.7,0,3.2-1,3.8-2.5l34.6-83.7h-14.7C223,36.2,221.5,37.2,220.9,38.8z"
          />
          <path
          fill="#FFFFFF"
          d="M16.3,36.4H0v16h16v69.8c5.5,0,10.7,0,16,0c0-16.1,0-50.1,0-70.2C31.9,43.4,24.9,36.4,16.3,36.4z"
          />
          <path
          fill="#FFFFFF"
          d="M580.9,36.6h-16.6v11.1c-16.5-18.5-44.6-16.2-59.6-2.4c-18.7,17.2-19.4,46.5-1.8,66
          c7.7,8.5,17.9,12.3,29.1,12.9c11.4,0.6,21.3-2.8,29.8-11.2c2.5,5.7,8.4,9.6,14.9,9.6c1,0,20,0,20,0v-16h-15.8V36.6z M535.7,107.7
          c-16.2-0.1-28-12.3-28-29.1c0-15.9,12.1-27.9,28.2-27.8c16.4,0.1,28.6,12.3,28.6,28.6C564.4,95.7,552.2,107.8,535.7,107.7z"
          />
          <path
          fill="#FFFFFF"
          d="M425.1,41.8l3.9-5.4h-9.4v0.1h-7.2c-2.3,0-4.4,1.1-5.7,2.9l-15.45,21.625L375.8,39.4
          c-1.3-1.8-3.4-2.9-5.7-2.9h-7.2v-0.1h-9.4l3.9,5.4l23.835,33.242L347.4,122.4h6.6c0.1,0,0.1,0,0.1,0l11.2-0.1
          c1.3,0,2.5-0.6,3.3-1.7l22.65-31.59l22.65,31.59c0.8,1.1,2,1.7,3.3,1.7l11.2,0.1c0,0,0,0,0.1,0h6.6l-33.835-47.358L425.1,41.8z"
          />
          <rect x="451.854" y="36.24" fill="#FFFFFF" width="15.904" height="85.726"/>
        </g>
      </defs>
    </svg>
    <div class="logo-link">
      <a class="" href="https://invoxia.com/" target="_blank">
        <svg viewBox="0 0 596.9 124.2">
          <defs>
            <linearGradient
            id="logo_gradient_H"
            gradientUnits="objectBoundingBox"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="200%"
            >
              <stop stop-color="#47d7ac" offset="0.5"/>
              <stop stop-color="#FFFFFF" offset="0.5"/>
              </linearGradient>
              <linearGradient
              id="logo_gradient_M"
              gradientUnits="objectBoundingBox"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="200%"
              >
              <stop stop-color="#ff7f41" offset="0.5"/>
              <stop stop-color="#FFFFFF" offset="0.5"/>
              </linearGradient>
              <linearGradient
              id="logo_gradient_D"
              gradientUnits="objectBoundingBox"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="200%"
              >
              <stop stop-color="#ffbe00" offset="0.5"/>
              <stop stop-color="#FFFFFF" offset="0.5"/>
            </linearGradient>
          </defs>
          <use xlink:href="#invoxia-logo" class="logo_i"/>
        </svg>
      </a>
      <span v-if="linkData">{{ $t('link_expiration_date') }} {{ linkData.expiration_date | formatDateDDMMYYYY }}</span>
    </div>
    <div class="map-container">
      <div ref="map" id="pub_map"></div>
    </div>
    <div class="link-info-container" v-if="linkData" ref="infoContainer">
      <div class="link-info">
        <div class="history">
          <div @click="prevPeriod()" class="hide" :class="{show : show_left}">
            <span data-feather="chevron-left"></span>
          </div>
          <p style="">{{ title_range | formatDateDDMMYYYY }}</p>
          <div @click="nextPeriod()" class="hide" :class="{show : show_right}" >
            <span data-feather="chevron-right"></span>
          </div>
        </div>
        <div class="device">
          <h2>{{ linkData.name }}</h2>
          <p>{{ linkData.serial }}</p>
        </div>
        <div class="state-info" v-if="!isLastPoint()">
          <hr>
          <div class="last-info">
          <strong>{{ $t('last_location') }} : </strong>
          <span class="innertext">{{ linkData.last_location_date | formatShortDayHour }}</span>
          <button class="innerbutton" @click="refresh()">
            <span data-feather="refresh-ccw"></span>
          </button>
          </div>
          <div class="state">
            <span>{{ linkData.lat }}, {{ linkData.lng }}</span>
            <strong>{{ linkData.last_location }}</strong>
          </div>
        </div>
        <hr v-if="isLastPoint()">
        <span v-if="isLastPoint()" @click="jumpToLastPoint()" style="text-decoration: underline;">{{ $t('link_last_point') }}</span>
      </div>      
    </div>
    <div class="link-error" v-show="error">
      <p>{{ $t('public_link_error') }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import gmapsInit from '../gmaps';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.headers.post['Content-Type'] = 'application/json'

const PUB_HTTP = axios.create(
    {
        baseURL: window._env_.ITP_INVOXIA_WS_HOST,
        xsrfHeaderName: "X-CSRFTOKEN",
        xsrfCookieName: "csrftoken",
        xhrFields: {
            withCredentials: false
        }
    }
)

const feather = require('feather-icons')
const moment = require('moment');


let map = null;
let infoWindow = null;

function initMap(info) {
  if (map == null) {
    map = new google.maps.Map(document.getElementById('pub_map'), {
      zoom: 14,
      center: {lat: info.lat, lng: info.lng}
    });
  }
}

export default {
  name: 'PulicLinksShow',

  data() {
    return {
      token: null,
      linkData: null,
      error: false,
      markers: [],
      date_start: null,
      date_end: null,
      title_range: null,
      history: [],
      routePath: null,
    }
  },

  computed: {
    show_left() {
      if (!this.title_range){
        return false;
      } 
      // Format so it's only the date and the hour doesn't count
      if (this.$options.filters.formatDateDDMMYYYY(this.title_range) == this.$options.filters.formatDateDDMMYYYY(this.linkData.start_date)){
        return false;
      }
      return true;
    },
    show_right() {
      if (!this.title_range) {
        return false;
      }
      if (this.date_end > moment()){
        return false;
      }
      return true;
    },
  },

  methods: {
    jumpToLastPoint() {
      this.date_start = moment(this.linkData.last_location_date).startOf('day')
      this.date_end = moment(this.linkData.last_location_date).endOf('day')
      this.getHistory()
    },

    isLastPoint() {
      if (this.$options.filters.formatDateDDMMYYYY(this.title_range) != this.$options.filters.formatDateDDMMYYYY(this.linkData.last_location_date)) {
        return true
      }

      return false
    },

    refresh() {
      window.location.reload()
    },

    loadLastMap() {
      this.error = false;
      PUB_HTTP.get('/pub/trackers/' + this.token + '/')
      .then((re) => {
        this.linkData = re.data
        initMap(this.linkData);
        this.setLastMarker(map, this.linkData);
        if (this.linkData.mode != 'single') {
          this.getHistory()
        }
      })
      .catch((er) => {
        this.error = true;
        console.log(er);
      });
    },

    getHistory() {
      this.title_range = this.dateRangeTitle()
      let params = "?date_start=" + this.date_start.toISOString();
      params += "&date_end=" + this.date_end.toISOString();
      this.clearMap();
      PUB_HTTP.get('/pub/trackers/' + this.token + '/history/' + params)
        .then((re) => {
          this.history = re.data
          if (this.history.length) {
            this.setHistoryMarkers(map, this.history, true)
          }
        })
        .catch(console.error);
    },

    setLastMarker(map, info) {
      let marker = new google.maps.Marker({
        position: {lat: info.lat, lng: info.lng},
        map: map,
        title: info.name
      });
      this.markers.push(marker)
    },

    historyMarkerTitle(position, full_date) {
      let _count = position["count"]
      let date1 = moment(position["date_start"])

      let _format = "HH:mm"
      if (full_date) {
        _format = "LLL"
      }

      let _info = date1.format(_format)
      if (_count > 1) {
        let date2 = moment(position["date_end"])
        _info += "-" + date2.format(_format)
      }

      return _info
    },

    historyMarkerInfo(position, full_date) {
      let _count = position["count"];
      let date1 = moment(position["date_start"]);

      let _format = "HH:mm";
      if (full_date) {
        _format = "LLL";
      }

      let _info = date1.format(_format);
      if (_count > 1) {
        var date2 = moment(position["date_end"]);
        _info += "-" + date2.format(_format);
      }
      if (_count > 1) {
        _info += "<br>" + _count + " positions";
      }

      return _info;
    },

    clearMap() {
      if (this.routePath != null) {
        this.routePath.setMap(null);
        this.routePath = null;
      }
      if (infoWindow) {
        infoWindow.close();
      }
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
    },

    nextPeriod() {
      let _diff = Math.max(1, this.date_end.diff(this.date_start, "days"));
      this.date_start = this.date_start.add(_diff, "days");
      this.date_end = this.date_end.add(_diff, "days");
      this.title_range = this.dateRangeTitle();
      this.getHistory();
    },

    prevPeriod() {
      let _diff = Math.max(1, this.date_end.diff(this.date_start, "days"));
      this.date_start = this.date_start.subtract(_diff, "days");
      this.date_end = this.date_end.subtract(_diff, "days");
      this.title_range = this.dateRangeTitle();
      this.getHistory();
    },

    dateRangeTitle() {
      if (this.date_start == "") {
        return "...";
      }
      
      let _diff = this.date_end.diff(this.date_start, "hours");
      let _title = this.date_start.format();
      if (_diff > 24) {
        _title += " - " + this.date_end;
      }
      return _title;
    },

    setHistoryMarkers(map, positions, resize) {

      let bounds = new google.maps.LatLngBounds();
      let coordinates = [];

      let full_date = false
      let _diff = this.date_end.diff(this.date_start, "hours")
      if (_diff > 24) {
        full_date = true
      }

      for (let i = 0; i < positions.length; i++) {
        var position = positions[i]
        let point = {lat: position.lat, lng: position.lng}
        bounds.extend(point)
        coordinates.push(point)
        
        let marker = new google.maps.Marker({
          position: {lat: position.lat, lng: position.lng},
          map: map,
          title: this.historyMarkerTitle(position, full_date),
          zIndex: 999
        });

        let scale = 1;
        const date_start = Date.parse(position.date_start);
        const date_end = Date.parse(position.date_end);

        let _diff = date_end - date_start / 1000 / 60;
        if (position.count > 1 && _diff > 2) {
          scale = 1.5
        }
        if (i == positions.length - 1) {
          let icon = {
            url: "/images/" + "flag.png",
            scaledSize: new google.maps.Size(32 * scale, 32 * scale),
            anchor: new google.maps.Point(16 * scale, 24 * scale)
          };
          marker.setIcon(icon);
        } else {
          let size = scale * 10
          let icon = {
            url: "/images/" + "smallplace.png",
            scaledSize: new google.maps.Size(size * 2, size * 2),
            anchor: new google.maps.Point(size, size)
          };
          marker.setIcon(icon);
        }

        this.markers.push(marker)

        let self = this;

        // Allow each marker to have an info window
        google.maps.event.addListener(marker, "click", 
        (function (marker, i) {
          return function () {
            let position = positions[i]
            let full_date = false
            var _title = self.historyMarkerInfo(position, full_date);
            if (infoWindow) {
              infoWindow.close();
            }
            infoWindow = new google.maps.InfoWindow();
            infoWindow.setContent(_title);
            infoWindow.open(map, marker);
          };
        })(marker, i))
      }

      if (resize && positions.length) {
        google.maps.event.addListenerOnce(map, "bounds_changed", function () {
          this.setZoom(Math.min(15, this.getZoom()));
        });
        let height = this.$refs.infoContainer.clientHeight;
        map.fitBounds(bounds, {top: 30, bottom: height-30});
      }

      this.routePath = new google.maps.Polyline({
        path: coordinates,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2
      })

      this.routePath.setMap(map)
    }
  },

  async mounted() {
    window.zE('webWidget', 'hide');
    const google = await gmapsInit();

    let parameters = this.$route.query;
    if (parameters["token"]) {
      let token = parameters["token"];
      this.token = token
    }

    this.date_start = moment().startOf('day');
    this.date_end = moment().endOf('day');

    this.loadLastMap();
  },
  updated() {
    feather.replace()
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#launcher {
  visibility: hidden !important;
}

.public-link-show {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  width: 100%;
  h1,h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 0;
  }

  p{
    margin-bottom: 0;
  }

  hr {
    margin: 0;
  }

  .hide {
    visibility: hidden;
  }

  .show {
    visibility: visible;
  }

  .map-container {
    // width: 100%;
    flex-grow: 1;
    position: relative;
    #pub_map {
      min-width: 100%;
      height: 100%;
      border-radius: 1em;
    }
  }

  .logo-link {
    z-index: 500;
    // position: absolute;
    background: transparent;
    padding: .5em;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    span {
      font-size: .7em;
    }
    a {
      width: 100px;
      display: block;
      background-color: $color-main;
      padding: .5em;
      border-radius: .5em;
    }
  }

  .link-info-container {
    z-index: 500;
    position: absolute;
    bottom: 0;
    background: transparent;
    padding: .5em;
    width: 100%;
    @media screen and (min-width: 1000px) {
      width: 40%;
    }
    .link-info {
    resize: block;

      border-radius: .5em;
      background-color: $color-main;
      color: #F1F8FC;
      padding: .8em;
      display: flex;
      flex-direction: column;
      gap: .5em;

      .history {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.304);
        border-radius: 100px;
        position: relative;
        padding: 2px 2px;
        svg {
          color: $color-main;
          background-color: #F1F8FC;
          border-radius: 50%;
          text-align: center;
          height: 2em;
          width: 2em;
        }
        p{
          font-weight: 600;
          color: #F1F8FC;
        }
        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .device {
        display: flex;
        gap: 2em;
        align-items: flex-end;
        p {
          font-size: .8em;
          opacity: .8;
        }
      }

      .state-info {
        width: 100%;

        .last-info {
          flex-direction: row;
          
          .innertext {
            padding: 0.5em;
          }

          .innerbutton {
            flex-direction: row;
            background-color: white;
            color: $color-main;
            border-radius: 1em;
            padding: 0.5em;
            border-style: none;
          }
        }
        .state {
          width: 100%;
          border-radius: .5em;
          color: black;
          display: flex;
          flex-direction: column;
          color: white;
          .title {
            opacity: .8;
            text-transform: uppercase;
            font-size: .7em;
            color: $color-main;
          }
        }
      }

      .info-device {
        display: none;
        width: 100%;
        gap: .5em;
        align-items: flex-start;
        align-self: stretch;
        .battery {
          padding: .7em;
          border-radius: .5em;
          background-color: #F1F8FC;
          color: black;
          flex: 1 0 0;
          font-size: .8em;
        }

        .more {
          background-color: #F1F8FC;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          padding: 0 .6em;
          border-radius: .5em;
          svg {
            color: $color-main;
          }
        }
      }
    }
  }
}

.link-error {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #D83F31;
  font-weight: 700;
  p {
    font-size: 2em;
    text-align: center;
  }
}
</style>